import React, { useEffect, useState } from 'react';
import Navbar from '../nav';
// import HookUpComponent from './search';
import { Link } from 'react-router-dom';
import Loader from '../loader';

const TopicsComponent = () => {
    const [topics, setTopics] = useState([]);
    const [isSearchingVisible, setIsSearchingVisible] = useState(false);

    const [loading, setIsLoaderVisible] = useState(true);

    useEffect(() => {
        fetchTopics();
    }, []);

    const fetchTopics = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/topics`);
            const data = await response.json();
            setTopics(data.data);
            setIsLoaderVisible(false);
        } catch (error) {
            console.error('Error fetching contests:', error);
        }
    };

    return (
        <>
            <Navbar />

            {/* {isSearchingVisible && <HookUpComponent />} */}

            {loading && <Loader />}

            {!loading && (
                <div className='p-4'>
                    <div className='text-sm text-gray-600'>
                        <p>Active topics
                            <button className='ml-2 btn btn-sm btn-default' onClick={(e) => setIsSearchingVisible(!isSearchingVisible)}>Search</button>
                        </p>
                        <br></br>
                        <small>Find active contests in these topics</small>
                        <br></br>
                    </div>

                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                        {topics.length && topics.map((topic, index) => (
                            <div key={index} className="rounded-lg overflow-hidden shadow-lg bg-green-50 text-sm">
                                <div className="w-full h-20 overflow-hidden">
                                    <img src={'/images/' + topic.image} alt={topic.name} className="w-full h-full object-cover" />
                                </div>

                                {/* User information */}
                                <div className="p-4">
                                    <h3 className="text-lg font-semibold mb-2">{topic.name}</h3>
                                    <p className="text-gray-700 mb-4">{topic.description}</p>
                                    <span className="inline-block bg-gray-200 text-gray-700 px-2 py-1 text-xs font-semibold rounded-full">
                                        {topic.category}
                                    </span>

                                    <Link to={`/topics/${topic.id}/contests/?topic=${topic.name}`} className="ml-4 btn btn-xs btn-accent">
                                        View contests
                                    </Link>
                                </div>
                            </div>
                        ))}

                        <br></br>
                        <br></br>

                        {/* <div class="carousel carousel-center max-w-md p-4 space-x-4 bg-gray-100 rounded-box">
                            {topics.length && topics.map((topic, index) => (
                                <div class="carousel-item">
                                    <img src="https://img.daisyui.com/images/stock/photo-1559703248-dcaaec9fab78.jpg" class="rounded-box" />
                                    <div class="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-white p-4">
                                        <h2 class="text-3xl font-bold">{topic.name}</h2>
                                        <p class="mt-2">{topic.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div> */}

                    </div>
                </div>
            )}
        </>
    );
};

export default TopicsComponent;
