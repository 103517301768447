import React from 'react';

const ProgressBar = ({ progress = 0 }) => {

    if (!progress) progress = 0

    const roundedProgress = Math.round(progress);

    if (roundedProgress === 100) return (
        <div className="radial-progress bg-gray-200 text-gray-600" style={{ "--value": roundedProgress }} role="progressbar">100%</div>
    )

    return (
        <div className="radial-progress bg-gray-50 text-gray-500" style={{ "--value": roundedProgress }} role="progressbar">{roundedProgress}%</div>
    );
};

export default ProgressBar;
