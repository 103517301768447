import React, { useEffect, useState, useRef } from 'react';
import Navbar from '../nav';
// import HookUpComponent from './search';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Loader from '../loader';
import { FixedSizeList } from 'react-window';

import './index.css'

const TopicContests = () => {
    const navigate = useNavigate();
    const { topic_id, topic } = useParams();

    const [searchParams, setSearchParams] = useSearchParams();

    const [contests, setContests] = useState([]);
    const [loading, setLoading] = useState(true);
    const contestItemRef = useRef(null);

    const [access_token, setAccessToken] = useState(localStorage.getItem('verified_access_token'))

    useEffect(() => {
        const fetchContests = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/contests/${topic_id}`, {
                    headers: {
                        'Authorization': `Bearer ${access_token}`
                    }
                })

                const data = await response.json();
                if (response.status == 401) navigate('/join');

                // const res = await fetch(`${process.env.REACT_APP_API_URL}/topics/${topic_id}`);
                // const topic = await res.json();

                // console.log(topic);

                setContests(data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching contests:', error);
            }
        };

        fetchContests();
    }, [topic_id]);

    useEffect(() => {
        if (contestItemRef.current) {
            const itemHeight = contestItemRef.current.getBoundingClientRect().height;
            setItemHeight(itemHeight);
        }
    }, [contests]);

    const [itemHeight, setItemHeight] = useState(500); // Default item height

    // Render individual contest item
    const ContestItem = ({ index, style }) => {
        const contest = contests[index];
        return (
            <div ref={contestItemRef} style={style} className={`text-sm`}>
                <div className={`bg-green-${contest.status ? 100 : 200} rounded-lg overflow-scroll shadow-lg`}>
                    <div className="w-full h-100 overflow-hidden">
                        <img src={'/images/' + contest.image} alt={contest.title} className="w-full h-full object-cover" />
                    </div>

                    <div className='p-4'>
                        <h3 className={`font-bold text-${contest.status ? '' : 'lg'}`}>{contest.title}</h3>
                        <p className="text-gray-700 mb-2 text-sm contest-description">{contest.description}</p>

                        <div className="flex items-center">
                            <span className="inline-block bg-gray-200 text-gray-500 px-2 py-1 text-xs font-semibold rounded-lg">
                                {new Date(contest.start_time).toLocaleDateString()}
                            </span>
                            <span className="ml-2 inline-block bg-gray-200 text-gray-500 px-2 py-1 text-xs font-semibold rounded-lg">
                                {new Date(contest.start_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' })}
                                -
                                {new Date(contest.end_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' })}
                            </span>

                            {contest.status === 1 && (
                                <span className='m-2 inline-block bg-warning-100 text-warning-500 px-2 py-1 text-xs font-semibold rounded-lg'>Ended</span>
                            )}

                            {contest.status === 0 && (
                                <div className='float-right'>
                                    <Link to={`/topics/${topic_id}/contests/${contest.id}`} className="ml-2 btn btn-xs btn-secondary">
                                        Join
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <>
            <Navbar />
            {loading && <Loader />}
            {!loading && (
                <div className='p-4'>
                    <div className='text-sm text-gray-600'>
                        <p>Active contests in <span className='text-gray-900 text-md font-bold'>{searchParams.get('topic')}</span>
                        </p>
                        <br></br>
                        <br></br>
                    </div>
                    {!contests.length && <div className='alert alert-default'><p>
                        It's a bit quiet rn 	&#x1F60C;</p></div>}
                    <FixedSizeList
                        height={800}
                        itemCount={contests.length}
                        itemSize={itemHeight}
                        width={'100%'}
                    >
                        {ContestItem}
                    </FixedSizeList>
                </div>
            )}
        </>
    );
};

export default TopicContests;
