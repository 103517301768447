import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Navbar from "../nav";
import axios from "axios";

export default function SignupForm() {
    const [formData, setFormData] = useState({
        mobile: '',
        username: '',
        otp: ''
    });

    const navigate = useNavigate();

    const [checking_user, setCheckingUser] = useState(false)
    const [sending_otp, setSendingOtp] = useState(false)

    const [logging_in, setLoggingIn] = useState(false)

    const [showOTPForm, setShowOTPForm] = useState(false); // State to manage OTP form visibility
    const [showPasswordForm, setShowPasswordForm] = useState(false); // State to manage OTP form visibility

    const handleSubmitPhone = async (event) => {
        event.preventDefault();

        // Check if user is already signed up
        try {
            setCheckingUser(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/checkuser`, { mobile: formData.mobile });
            const { exists } = response.data;
            if (exists) {
                // User is already signed up, do nothing
                setShowPasswordForm(true);
                setShowOTPForm(false);
                setCheckingUser(false);
            } else {
                // User is not signed up, show OTP form and send OTP
                setShowOTPForm(true);
                handleChange({ target: { name: 'otp', value: '33434344' } });
                setShowPasswordForm(true);
                setCheckingUser(false);
                setSendingOtp(true);
                await axios.post(`${process.env.REACT_APP_API_URL}/sendotp`, { mobile: formData.mobile });
                setSendingOtp(false);
                alert('Welcome! We\'ve sent you a One Time Password to help verify your mobile number. Please check your phone for an SMS with the OTP, then complete signup below.');
            }
        } catch (error) {
            console.error("Error checking user:", error);
        }
    };

    const handleLogin = async (event) => {
        event.preventDefault();

        try {
            setLoggingIn(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/signup`, formData);
            const { signed_in, access_token, registered, profile } = response.data;

            if (signed_in) {
                localStorage.setItem("verified_access_token", access_token);
                localStorage.setItem("verifiq_profile", JSON.stringify(profile));
                setLoggingIn(false);
                navigate("/topics");
            }

            if (registered) {
                setLoggingIn(false);
                alert("You have successfully registered. Please refresh the page to login")
                window.location.reload()
            }

        } catch (error) {
            setLoggingIn(false);
            if (error.response && error.response.status === 401) {
                alert("Sorry, could not log you in, please check your credentials.");
            } else {
                console.error("Error signing up:", error);
            }
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <>
            <Navbar />
            <div className="p-4">

                <div className="card bg-white p-6 rounded-lg shadow-md">
                    <h2 className="font-semibold text-2xl mb-2 mt-2">Welcome! </h2>
                    <span className="text-gray-600 text-sm">Learning shouldn't be so boring, enter your phone number to get access</span>
                    <br></br>

                    <hr className="mt-6"></hr>
                    <form className="mt-4" onSubmit={showPasswordForm ? handleLogin : handleSubmitPhone}>
                        <div className="mb-6">
                            <label className="text-sm block text-gray-700 font-bold mb-2" htmlFor="mobile">
                                Mobile number (for verification)
                            </label>
                            <input autoComplete="false" type="text" name="mobile" placeholder="Type here" className="input  input-sm input-bordered w-full" onChange={handleChange} />
                            {/* <span className="text-xs text-gray-500 d-block">We do not share any private information with any third party</span> */}
                        </div>

                        {showOTPForm && (<>
                            <div className="mb-6">
                                <label className="text-sm block text-gray-700 font-bold mb-2" htmlFor="otp">
                                    Full name (for profile)
                                </label>
                                <input type="text" name="username" placeholder="Enter Username" className="input  input-sm input-bordered w-full" onChange={handleChange} />
                            </div>

                            {/* <div className="mb-6">
                                <label className="text-sm block text-gray-700 font-bold mb-2" htmlFor="otp">
                                    OTP
                                </label>
                                <input type="text" name="otp" placeholder="Enter OTP" className="input  input-sm input-bordered w-full" onChange={handleChange} />
                            </div> */}
                        </>)}

                        {showPasswordForm && (
                            <div className="mb-6">
                                <label className="text-sm block text-gray-700 font-bold mb-2" htmlFor="password">
                                    Password
                                </label>
                                <input type="password" name="password" placeholder="Enter Password" className="input  input-sm input-bordered w-full" onChange={handleChange} />
                            </div>
                        )}

                        <div className="mb-6">
                            <button disabled={logging_in || checking_user || sending_otp} className="btn btn-primary" type="submit">
                                {logging_in || checking_user || sending_otp ? 'Please wait...' : showOTPForm ? 'Finish' : 'Continue'}
                            </button>

                            <Link className="ml-4 btn btn-warning" to="/" title="See yo next time &#x1F60E;">
                                Not right now
                            </Link>
                        </div>
                    </form>

                    <hr className="mt-4"></hr>
                    <p className="mt-2 text-sm text-gray-500 mb-10">
                        Please read our <Link to="/terms" className="text-blue-600">Terms & Conditions</Link> before you proceed to do anything. Continuing will mean you agree to our Terms & Conditions.
                    </p>
                </div>
            </div>
        </>
    );
}
