import axios from "axios";
import { useEffect, useState } from "react";

const ContestResults = ({ contestId, access_token }) => {
    const [results, setResults] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchResults = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/contests/${contestId}/rank`, {
                    headers: {
                        'Authorization': `Bearer ${access_token}`
                    }
                });

                setResults(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching results:', error);
                setLoading(false);
            }
        };

        fetchResults();
    }, [contestId]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!results) {
        return <div>No results available</div>;
    }

    const { overall_ranking, per_question_ranking } = results;

    console.log(per_question_ranking);

    return (
        <div className="mt-4">

            <div className="bg-green-100 rounded-lg p-4 mb-4 shadow-2xl">
                <h2 className="text-4xl mb-6 font-bold text-green-900">Contest Results &#x1F389;</h2>

                <h3 className="text-lg font-bold mb-2">Overall Ranking</h3>

                <ul className="space-y-2">
                    {overall_ranking.map((user) => (
                        <li key={user.user_id} className="flex justify-between items-center bg-green-300 rounded-md p-2 shadow-md">
                            <div className="font-bold text-gray-700">#{user.rank} <span className="text-gray-600">{user.username}</span></div>
                            <div className="font-bold text-green-600">{user.reward}</div>
                        </li>
                    ))}
                </ul>
            </div>

            <h3 className="text-lg font-bold mb-2 mt-8">Per Question Ranking</h3>
            {Object.keys(per_question_ranking).map((question, index) => (
                <div className="bg-orange-100 rounded-lg p-4 mb-4">
                    <div className="" key={index}>
                        <h4 className="p-2 rounded-md bg-orange-200 font-semibold mb-2 text-gray-700 text-xs">#{index + 1}: {question}</h4>
                    </div>

                    <ul className="space-y-2">
                        {per_question_ranking[question].map((ranking, index) => (
                            <li className="flex justify-between items-center bg-orange p-2 text-xs"
                                style={{
                                    borderBottom: index === per_question_ranking[question].length - 1 ? 'none' : '0.55px solid rgb(226, 226, 226)'
                                }}
                                key={index}>
                                <div className="font-bold text-gray-700">#{ranking.rank} <span className="text-gray-600">{ranking.username}</span></div>
                                <div className="font-bold text-green-600">{ranking.reward ?? 0}</div>
                            </li>
                        ))}
                    </ul>
                </div>

            ))}
        </div>
    );
};

export default ContestResults;
