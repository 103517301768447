const Question = ({ question, options, onSelect, selectedOption, currentQuestionResponse = false }) => {
    return (
        <div>
            <h3 className="font-semibold">{question.question}</h3>
            <ul className='pl-2'>
                {options.map((option, index) => (
                    <li key={index}>
                        {/* <label>
                            <input
                                type="checkbox"
                                className="checkbox"
                                checked={selectedOption === option}
                                onChange={() => onSelect(option)}
                            />
                            {option}
                        </label> */}

                        <div className="form-control">
                            <label className="label cursor-pointer">
                                <span className="label-text">{option.name}</span>
                                <input type="checkbox"
                                    checked={selectedOption === option.id}
                                    onChange={() => {
                                        option['question_id'] = question.id
                                        onSelect(option)
                                    }}

                                    // value={option.id}


                                    className={currentQuestionResponse ? `checkbox checkbox-success` : 'checkbox'} />
                            </label>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Question;