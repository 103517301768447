import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function Navbar({ credit_balance = 0 }) {

    const exchange_rate = process.env.REACT_APP_EXCHANGE_RATE

    const [profile, setProfile] = useState(null);

    const handleLogout = () => {
        localStorage.removeItem('verified_access_token');
        localStorage.removeItem('verifiq_profile');
        window.location.href = '/#/join';
    };

    useEffect(() => {
        const storedProfile = localStorage.getItem('verifiq_profile');
        if (storedProfile !== null) {
            const parsedProfile = JSON.parse(storedProfile);
            setProfile(parsedProfile);
        } else {
            setProfile(null);
        }
    }, []);

    return (
        <div className=''>
            <nav className="navbar bg-gray-700 text-gray-100 border-b">
                <div className='p-25 container mx-auto'>
                    <Link to="/" className="flex-1 text-lg font-bold">
                        {/* <span style={{
                            padding: '10px',
                            border: '1px solid #838c9a',
                            borderRadius: '10px'
                        }}>verifiQ &#x2714;</span> */}
                        <img src={'/images/logo-white.png'} alt="verifiq" width={90} />
                    </Link>

                    <div className="flex-none">
                        <div className="dropdown dropdown-end mr-4">
                            <div tabIndex={0} role="button" className="btn btn-ghost btn-circle px-15">
                                <div className="indicator">
                                    <span>&#x1F4B0;</span>
                                    <span className="badge badge-sm indicator-item">{profile?.credit_balance ?? 0}</span>
                                </div>
                            </div>
                            <div tabIndex={0} className="mt-1 z-[1] card card-compact dropdown-content w-52 bg-green-100 shadow">
                                <div className="card-body">
                                    <span className="font-bold text-lg text-primary">{profile?.credit_balance ?? 0} Credits</span>
                                    <span className="text-secondary text-sm">~ KES {profile?.credit_balance ?? 0 ? Math.round(parseInt(profile?.credit_balance ?? 0) / exchange_rate) : 0}</span>
                                    <div className="card-actions mt-2">
                                        <Link to={'/market'} className="btn btn-success btn-sm">Buy credits</Link>
                                        {(profile?.credit_balance > 0) && <Link to={'/market/withdraw'} className="btn btn-warning btn-sm btn-blockmb-1">Withdraw/Sell</Link>}
                                        <button className="btn btn-primary btn-sm btn-block" onClick={handleLogout}>Logout</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}
