import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../nav';

export default function Market() {
    const [formData, setFormData] = useState({
        userPhoneNumber: '',
        userName: '',
        creditsToBuy: '',
        totalAmount: 0,
        buyFromSystem: true,
    });
    const [access_token] = useState(localStorage.getItem('verified_access_token'));
    const [loading, setLoading] = useState(false);

    // Exchange rate
    const exchange_rate = process.env.REACT_APP_EXCHANGE_RATE;
    const system_commission = process.env.REACT_APP_CREDIT_SALE_SYSTEM_COMMISION;

    useEffect(() => {
        // Calculate total amount whenever creditsToBuy or buyFromSystem changes
        const credits = parseInt(formData.creditsToBuy, 10);
        if (!isNaN(credits)) {
            const totalAmount = Math.round(formData.buyFromSystem
                ? (credits / exchange_rate) * (1 + parseFloat(system_commission)) // Calculate total amount with 15% commission
                : credits / exchange_rate); // Flat rate without commission
            setFormData(prevState => ({
                ...prevState,
                totalAmount
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                totalAmount: 0
            }));
        }
    }, [formData.creditsToBuy, formData.buyFromSystem]);

    const handleBuyCredits = async (e) => {
        e.preventDefault();

        try {
            setLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/buy-credits`, {
                mobile: formData.userPhoneNumber,
                credits: formData.creditsToBuy,
                totalAmount: formData.totalAmount,
                buyFromSystem: formData.buyFromSystem,
            }, {
                headers: {
                    'Authorization': `Token ${access_token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.success) {
                alert('Credits purchase successfully initiated!');
                // Update user credits here if needed
            } else {
                alert('Failed to purchase credits. Please try again later.');
            }
        } catch (error) {
            console.error('Error purchasing credits:', error);
            alert('Error purchasing credits. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleSelectContact = async () => {
        if (!('contacts' in navigator && 'ContactsManager' in window)) {
            alert('Contacts API not supported on this device');
            return;
        }

        try {
            const contacts = await navigator.contacts.select(['name', 'tel'], { multiple: false });
            if (contacts.length === 0) return;

            const contact = contacts[0];
            setFormData(prevState => ({
                ...prevState,
                userPhoneNumber: contact.tel[0],
                userName: contact.name[0],
            }));
        } catch (error) {
            console.error('Error selecting contact:', error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleBuyFromSystemChange = () => {
        setFormData(prevState => ({
            ...prevState,
            buyFromSystem: !prevState.buyFromSystem,
        }));
    };

    const verifyPayment = async (e) => {
        e.preventDefault()

        console.log("verify payment");

        const response = await axios.post(`${process.env.REACT_APP_API_URL}/buy-credits-verify`, {}, {
            headers: {
                'Authorization': `Token ${access_token}`,
                'Content-Type': 'application/json'
            }
        });
    }

    return (
        <>
            <Navbar />

            <form onSubmit={handleBuyCredits} method='post' className='m-3'>
                <div className='card bg-yellow-50 p-4 mt-6 shadow-2xl bg-light-ggreen'>
                    <div className='border-b-2 border-gray-400'>
                        <h3 className='font-bold text-lg text-gray-700'>Buy Credits</h3>
                        <p className='text-sm text-gray-500 mb-2'>You can buy credits from the system, or request a friend to transfer to you.</p>
                    </div>

                    <div className='mt-4 mb-6' style={{
                        background: 'rgba(142, 150, 140, 0.19)',
                        borderRadius: '10px',
                        padding: '10px'
                    }}>
                        <label className='text-gray-700 text-sm font-semibold'>Buy from:</label>
                        <div>
                            <div className="form-control">
                                <label className="label cursor-pointer">
                                    <span className="label-text">System (plus 15% transfer fee)</span>
                                    <input type="radio" className="radio radio-info checked:bg-green-600" value="system" checked={formData.buyFromSystem} onChange={handleBuyFromSystemChange} />
                                </label>
                            </div>

                            <div className="form-control">
                                <label className="label cursor-pointer">
                                    <span className="label-text">My contact (no transaction charge)</span>
                                    <input type="radio" className="radio radio-info checked:bg-green-600" value="custom_contact" checked={!formData.buyFromSystem} onChange={handleBuyFromSystemChange} />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="form-control mb-6" style={{
                        background: 'rgba(142, 150, 140, 0.11)',
                        borderRadius: '10px',
                        padding: '10px'
                    }}>
                        <label className='text-gray-700 text-sm font-semibold mb-2'>{formData.buyFromSystem ? "Your " : "Your contact's "} phone number</label>
                        <div className={`${formData.buyFromSystem ? '' : 'flex space-x-2'}`}>
                            <input type="tel" className={`input ${formData.buyFromSystem ? 'w-full' : 'min-w-40'}`} name="userPhoneNumber" value={formData.userPhoneNumber} onChange={handleInputChange} placeholder="Enter phone no." />
                            {!formData.buyFromSystem && <button type="button" disabled={formData.buyFromSystem} className="btn btn-secondary" onClick={handleSelectContact}>
                                Contacts
                            </button>}
                        </div>
                        <span className="text-xs text-gray-600">{formData.buyFromSystem ? 'Enter your phone number for payment processing via M-pesa' : 'Enter the contact\'s phone number or select from your contact list. Once they receive a request frm you, they will confirm and the credits will be transfered to your account'}</span>
                    </div>

                    <div className='form-control' style={{
                        background: 'rgba(142, 150, 140, 0.11)',
                        borderRadius: '10px',
                        padding: '10px'
                    }}>
                        <label className='text-gray-700 text-sm font-semibold mb-2'>How many credits do you need? </label>
                        <input type="number" required className='input w-full' name="creditsToBuy" value={formData.creditsToBuy} onChange={handleInputChange} placeholder="Enter credits to buy" />
                        <span className="text-xs text-gray-600">A contest typically requires about {Math.round(exchange_rate * 30)} credits to join</span>
                    </div>

                    <div className='mt-2 text-gray-600 text-sm'>
                        <p>Total amount to spend: KES {formData.totalAmount.toFixed(2)}</p>
                    </div>

                    <div className='mt-4'>
                        <button type='submit' className='btn btn-primary' disabled={loading}>{loading ? 'Loading...' : 'Buy Credits'}</button>

                        <div className='float-right'>
                            <button onClick={(e) => verifyPayment(e)} className='btn btn-secondary' disabled={loading}>{loading ? 'Loading...' : 'Verify Purchase'}</button>
                        </div>
                    </div>
                </div>

                <hr className='mt-6 text-green-900' />

                <p className='mt-6 text-xs text-gray-600'>By clicking buy, you agree to our <a href='/#/terms' className='text-primary'>Terms of Service</a></p>
            </form>
        </>
    );
}
