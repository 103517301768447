import React, { useState, useEffect } from 'react';
import ProgressBar from './progerssbar';
import { Link } from 'react-router-dom';

import moment from 'moment';

const ContestCountdown = (props) => {
    const [timeUntilStart, setTimeUntilStart] = useState(null);

    useEffect(() => {
        // Parse the start time in UTC
        let _start = moment.utc(props.contest_details.start_time);
        let _end = moment.utc(props.contest_details.end_time);
        // Get the current time in UTC
        let _now = moment().utc(true);

        // If end time is past current time
        // if (_end.isAfter(_now)) {
        //     console.log("this has ended", _end, _now);
        // } else {
        //     console.log("this has NOT Ended", _end, _now);
        // }


        // Calculate the difference in seconds
        const timeUntilStart = _start.diff(_now, 'seconds');
        // console.log("USING MOMENT", timeUntilStart, _start.format('YYYY-MM-DD HH:mm:ss'), "----------", _now.format('YYYY-MM-DD HH:mm:ss'));

        // return _start - _now

        setTimeUntilStart(timeUntilStart)

        // };

        // console.log("TIME UNTIL START", getTimeUntilStartInSeconds(props.contest_details.start_time));


        const interval = setInterval(() => {
            setTimeUntilStart(prevTime => prevTime - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [props.remaining_time]);

    if (timeUntilStart === null) {
        return <div>Loading...</div>;
    }

    if (timeUntilStart < 1) {
        return <>{props.children}</>;
    }

    const days = Math.floor(timeUntilStart / (24 * 60 * 60));
    const hours = Math.floor((timeUntilStart % (24 * 60 * 60)) / (60 * 60));
    const minutes = Math.floor((timeUntilStart % (60 * 60)) / 60);
    const seconds = Math.floor(timeUntilStart % 60);

    return (
        <div className='card shadow-lg rounded-lg bg-green-200 font-semibold'>
            <div className="stat mb-4">
                <h2 className='text-xl mb-2'>{props.contest_details.title}</h2>
                <p className='text-gray-600 text-sm mb-10'>{props.contest_details.description}</p>

                <div className='card rounded-lg bg-green-100 p-4'>
                    <div className="stat-title mb-2">Starts in:</div>
                    <div className="stat-value text-gray-600"> {days ? `${days} days, ` : ''}{hours}:{minutes}:{seconds}</div>
                    <div className="stat-desc mt-2">Contest will start at: <br />
                        {/* {new Date(props.contest_details['start_time']).toLocaleTimeString()} */}
                        {new Date(props.contest_details.start_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', timeZone: 'UTC' })}
                    </div>
                </div>

                <Link to={'/topics'} className='mt-6 btn btn-sm btn-secondary'>View other topics</Link>
            </div>


        </div>
    );
};

export default ContestCountdown;
