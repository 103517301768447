// Filename - App.js

import React, { Component } from "react";
import {
  HashRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import MainApp from "./components/home";
import SignUp from "./components/auth/signup";
import TopicContests from "./components/contest";
import TopicsComponent from "./components/contest/topics";
import Terms from "./components/home/terms";

import "./App.css";
import MainGameComponent from "./components/contest/game";
import CreateContest from "./components/contest/create";
import Market from "./components/market/buy";
import SellCredits from "./components/market/sell";

class App extends Component {
  render() {
    return (
      <Router>
        <Routes>
          <Route
            path="/"
            element={<MainApp />}
          ></Route>
          <Route
            path="/topics"
            element={<TopicsComponent />}
          ></Route>
          <Route
            path="/market"
            element={<Market />}
          ></Route>
          <Route
            path="/market/withdraw"
            element={<SellCredits />}
          ></Route>
          <Route
            path="/contests/create"
            element={<CreateContest />}
          ></Route>
          <Route
            path="/topics/:topic_id/contests"
            element={<TopicContests />}
          ></Route>
          <Route
            path="/topics/:topic_id/contests/:contest_id"
            element={<MainGameComponent />}
          ></Route>
          <Route
            path="/join"
            element={<SignUp />}
          ></Route>
          <Route
            path="/terms"
            element={<Terms />}
          ></Route>
        </Routes>
      </Router>
    );
  }
}

export default App;
